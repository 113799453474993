export enum ModelAnalysis {
  Stocks = 0,
  DowJones = 1
}

export interface VpaUser {
  STK: string;
  SS?: number;
  XLTDD: number;
  D: number;
  BV: number;
  E1: number;
  DEE: number;
  DSS: number;
  RBB: number;
  DRBB: number;
  E0: number;
  CP: number;
  HH: number;
}

export interface VpaResults {
  STK: string;
  V: number;
  E1: number;
  X1: number;
  X2: number;
  X3: number;
  X4: number;
  CP: number;
  RRF: number;
}

export interface VpaSystem extends VpaUser {
  F: number;
  DS: number;
  DE: number;
  RB: number;
  DRB: number;
  H: number;
  S: number;
  XLTD: number;
  AK3: number;
  RT: number;
  XL: number;
  ZK: number;
  AK1: number;
  AK2: number;
  AK: number;
  XLH: number;
  EQ: number;
  V1: number;
  V2: number;
  V4: number;
  V5: number;
  XK: number;
  VU: number;
  VM: number;
  VL: number;
  V: number;
  XINC: number;
  X1: number;
  X2: number;
  X3: number;
  X4: number;
  RRF: number;
}
