import { Action } from '@ngrx/store'

export const START_FETCHING_DATA = '[FETCHING_DATA] Start'
export const STOP_FETCHING_DATA = '[FETCHING_DATA] Stop'

export class StartFetchingData implements Action {
  readonly type = START_FETCHING_DATA;
  constructor() { }
}

export class StopFetchingData implements Action {
  readonly type = STOP_FETCHING_DATA;
  constructor() { }
}

export type Actions = StartFetchingData | StopFetchingData
