import { Component, OnInit } from '@angular/core';
import { faChartLine, faDollarSign, faGlobeAmericas } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  infos = this.getInfos();

  getInfos() {
    return [
      {
        icon: faChartLine,
        title: 'Stock Forum',
        body:
          `Here, you are a part of a
          community of investors that use a proprietary financial computer model to determine
          the intrinsic value of stocks by using fundamental data.  The Value Point Analysis Model
          allows you to evaluate stocks, post the results to The VPA Forum where the community can view,
          access and discuss in the The VPA Stock Talk chat room.`
      },
      {
        icon: faDollarSign,
        title: 'In-Depth',
        body:
          `What makes The VPA Model special is its ability to enable the user to test and play
          unlimited financial "scenarios" for any given equity. Thereby providing the user with
          an in-depth understanding of how that equity may fundamentally behave in the market place
          prior to making a purchase.`
      },
      {
        icon: faGlobeAmericas,
        title: 'Join',
        body:
          `Get started by clicking onto <a href="/#/Forum"> The VPA Forum</a > to view and access all of the latest
          postings or dive right in and start evaluating your own equities by entering through
          The <a href="/#/Model">VPA Model</a>.`
      }
    ];
  }
}