import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  constructor(private modalService: NgbModal) { }

  imageSrcs = ['merlin.gif', 'release.gif'];
  @ViewChild('image0', { static: true }) image0: any;
  @ViewChild('image1', { static: true }) image1: any;

  ngOnInit() {
  }

  openModalImage(imageSrc: any): void {
    if (imageSrc === this.imageSrcs[0]) {
      this.modalService.open(this.image0, { size: 'lg' } as NgbModalOptions);
    } else if (imageSrc === this.imageSrcs[1]) {
      this.modalService.open(this.image1, { size: 'lg' });
    }
  }
}