import { Component, ChangeDetectorRef, AfterViewChecked, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { faExternalLinkAlt, faCopyright } from '@fortawesome/free-solid-svg-icons';
import { AppState } from './app.state';
import { Store_FetchingData } from './store/actions/fetching-data.model';
import { Router, NavigationEnd } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit, AfterViewChecked {

  // ------------------------------------------------------
  // Ngrx Store
  // ------------------------------------------------------
  fetchingData$: Observable<Store_FetchingData> = this.store.select('fetchingData');

  currentYear = new Date().getFullYear();
  mainMenuItems = this.getMainMenuItems();
  isHamburgerOpen = false;
  faExternalLinkAlt = faExternalLinkAlt;
  faCopyright = faCopyright;
  @ViewChild('disclaimer', { static: true }) disclaimer: any;

  constructor(
    private store: Store<AppState>,
    private cdRef: ChangeDetectorRef,
    private router: Router,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.router.events.subscribe(val => {
      if (val instanceof NavigationEnd) {
        this.isHamburgerOpen = false;
        window.scrollTo(0, 0);
      }
    });
  }

  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }

  openModalDisclaimer() {
    this.modalService.open(this.disclaimer, { size: 'xl' });
  }

  getMainMenuItems() {
    return [
      { location: '/', name: 'Home' },
      {
        location: '/Model', name: 'VPA Model', subMenus: [
          { location: '/Stocks', name: 'Stocks' },
          { location: '/DowJones', name: 'Dow Jones' },
        ]
      },
      { location: '/Help', name: 'VPA Model Help' },
      { location: '/Forum', name: 'VPA Forum' },
      { location: '/Features', name: 'Features' },
      { location: '/About', name: 'About Us' },
    ];
  }
}