import { Store_FetchingData } from '../actions/fetching-data.model';
import * as FetchingDataActions from '../actions/fetching-data.actions';

const initialState: Store_FetchingData = {
  isFetchingData: false,
  fetchingDataCount: 0
}

export function fetchingDataReducer(
  state: Store_FetchingData = initialState,
  action: FetchingDataActions.Actions
): Store_FetchingData {

  switch (action.type) {
    case FetchingDataActions.START_FETCHING_DATA: {
      let fetchingDataCount = state.fetchingDataCount + 1;
      let isFetchingData = fetchingDataCount > 0;
      return { fetchingDataCount, isFetchingData };
    }
    case FetchingDataActions.STOP_FETCHING_DATA:
      let fetchingDataCount = Math.max(state.fetchingDataCount - 1, 0);
      let isFetchingData = fetchingDataCount > 0;
      return { fetchingDataCount, isFetchingData };
    default:
      return state;
  }
}