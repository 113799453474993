import { NgModule, NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LogPipe } from './log.pipe';
import { NgxMaskModule } from 'ngx-mask';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoadingInterceptor } from './components/loading/loading.interceptor';
import { LoadingComponent } from './components/loading/loading.component';
import { ModalSimpleComponent } from './components/modal-simple/modal-simple.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule,
  ],
  declarations: [
    LogPipe,
    LoadingComponent,
    ModalSimpleComponent
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    LogPipe,
    NgxMaskModule,
    LoadingComponent,
    ModalSimpleComponent
  ],
  entryComponents: [],
  schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true }
  ]
})
export class SharedModule { }
