import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from "@angular/common/http";
import { Observable } from "rxjs";
import { finalize } from "rxjs/operators";
import { Store } from "@ngrx/store";
import { AppState } from "src/app/app.state";
import * as FetchingDataActions from 'src/app/store/actions/fetching-data.actions'

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
  constructor(private store: Store<AppState>) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.store.dispatch(new FetchingDataActions.StartFetchingData());
    return next.handle(req).pipe(finalize(() => {
      this.store.dispatch(new FetchingDataActions.StopFetchingData());
    }))
  }
}