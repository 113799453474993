import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function OnlyNumbers(applyThisValidator: boolean): ValidatorFn {
  if (!applyThisValidator) {
    return function (_: AbstractControl): ValidationErrors | null {
      return null;
    }
  } else {
    return function (control: AbstractControl): ValidationErrors | null {
      let isValid: boolean = false;
      try {
        isValid = !isNaN(Number(control.value));
      } catch (error) {
        console.error(error);
      }
      return isValid ? null : { OnlyNumbers: true }
    }
  }
}