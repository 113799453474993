import { Component, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-modal-simple',
  template: `
    <div class="modal-header">
      <h3 class="vpaModalHeader">{{title}}</h3>
      <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body vpaModalBody">
      <p>{{body}}!</p>
    </div>
  `
})
export class ModalSimpleComponent {
  @Input() title: string;
  @Input() body: string;

  constructor(public activeModal: NgbActiveModal) { }
}