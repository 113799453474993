import { Routes } from "@angular/router";
import { HomeComponent } from "./components/home/home.component";
import { VpaModelWrapperComponent } from "./components/vpa-model-wrapper/vpa-model-wrapper.component";
import { VpaModelComponent } from "./components/vpa-model-wrapper/vpa-model/vpa-model.component";
import { VpaModelStocksComponent } from "./components/vpa-model-wrapper/vpa-model-stocks/vpa-model-stocks.component";
import { VpaModelDowJonesComponent } from "./components/vpa-model-wrapper/vpa-model-dow-jones/vpa-model-dow-jones.component";
import { HelpComponent } from "./components/help/help.component";
import { LinksComponent } from "./components/links/links.component";
import { AboutComponent } from "./components/about/about.component";
import { ForumComponent } from "./components/forum/forum.component";
import { FeaturesComponent } from "./components/features/features.component";
import { HttpsGuard } from "./shared/https.guard";

export const routes: Routes = [
  {
    path: '', canActivate: [HttpsGuard], component: HomeComponent, pathMatch: 'full'
  },
  {
    path: 'Model', canActivate: [HttpsGuard], component: VpaModelWrapperComponent,
    children: [
      {
        path: '', component: VpaModelComponent
      },
      {
        path: 'Stocks', component: VpaModelStocksComponent,
      },
      {
        path: 'DowJones', component: VpaModelDowJonesComponent,
      },
    ]
  },
  {
    path: 'Help', canActivate: [HttpsGuard], component: HelpComponent, pathMatch: 'full'
  },
  {
    path: 'Features', canActivate: [HttpsGuard], component: FeaturesComponent,
  },
  {
    path: 'Forum', canActivate: [HttpsGuard], component: ForumComponent,
  },
  {
    path: 'About', canActivate: [HttpsGuard], component: AboutComponent,
  },
  {
    path: 'Links', canActivate: [HttpsGuard], component: LinksComponent,
  },
  {
    path: '**', canActivate: [HttpsGuard], redirectTo: '/'
  },
];