import { AbstractControl, ValidationErrors } from '@angular/forms';

export function NoWhitespace(control: AbstractControl): ValidationErrors | null {
  let isValid: boolean = false;
  try {
    let isWhitespace: boolean = ((control.value + '') || '').trim().length === 0;
    isValid = !isWhitespace;
  } catch (error) {
    console.error(error);
  }
  return isValid ? null : { NoWhitespace: true }
}