import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbAccordionModule, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { fetchingDataReducer } from './store/reducers/fetching-data.reducer';
import { AppComponent } from './app.component';
import { CookieService } from 'ngx-cookie-service';
import { SharedModule } from './shared/shared.module';
import { CoreModule } from './core/core.module';
import { AngularWebStorageModule } from 'angular-web-storage';
import { NgxMaskModule } from 'ngx-mask';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { RouterModule } from '@angular/router';
import { routes } from './app.routes';
import { HomeComponent } from './components/home/home.component';
import { VpaModelComponent } from './components/vpa-model-wrapper/vpa-model/vpa-model.component';
import { VpaModelStocksComponent } from './components/vpa-model-wrapper/vpa-model-stocks/vpa-model-stocks.component';
import { VpaModelDowJonesComponent } from './components/vpa-model-wrapper/vpa-model-dow-jones/vpa-model-dow-jones.component';
import { HelpComponent } from './components/help/help.component';
import { FeaturesComponent } from './components/features/features.component';
import { ForumComponent } from './components/forum/forum.component';
import { AboutComponent } from './components/about/about.component';
import { LinksComponent } from './components/links/links.component';
import { VpaModelWrapperComponent } from './components/vpa-model-wrapper/vpa-model-wrapper.component';
import { ModalSimpleComponent } from './shared/components/modal-simple/modal-simple.component';
import { HttpsGuard } from './shared/https.guard';

const reducers = {
  fetchingData: fetchingDataReducer
};

const COMPONENTS = [
  AppComponent,
  HomeComponent,
  VpaModelWrapperComponent,
  VpaModelComponent,
  VpaModelStocksComponent,
  VpaModelDowJonesComponent,
  HelpComponent,
  FeaturesComponent,
  ForumComponent,
  AboutComponent,
  LinksComponent
]

@NgModule({
  declarations: [COMPONENTS],
  imports: [
    AngularWebStorageModule,
    RouterModule.forRoot(routes),
    StoreModule.forRoot(reducers),
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    SharedModule,
    NgxMaskModule.forRoot(),
    DeviceDetectorModule.forRoot(),
    FontAwesomeModule,
    NgbAccordionModule,
    NgbModalModule
  ],
  providers: [CookieService, HttpsGuard],
  bootstrap: [AppComponent],
  schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA],
  entryComponents: [ModalSimpleComponent]
})
export class AppModule {
}
