import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { NoWhitespace } from 'src/app/shared/validators/no-whitespace.validator';
import { GreaterThanZero } from 'src/app/shared/validators/greater-than-zero.validator';
import { OnlyNumbers } from 'src/app/shared/validators/only-numbers.validator';
import { NgbAccordion, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModelAnalysis, VpaUser } from 'src/app/shared/models/vpa-engine.models';
import { } from 'src/app/shared/models/vpa-engine.models';
import { ModalSimpleComponent } from 'src/app/shared/components/modal-simple/modal-simple.component';

declare const VPAEngine: any;

@Component({
  selector: 'app-vpa-model-stocks',
  templateUrl: './vpa-model-stocks.component.html',
  styleUrls: ['./vpa-model-stocks.component.scss']
})
export class VpaModelStocksComponent implements OnInit, AfterViewInit {
  faSpinner = faSpinner;
  stocksForm: FormGroup;
  gettingResults = false;
  results: any;
  inputs = this.getInputs();
  @ViewChild('ngbAccordion', { static: true }) ngbAccordion: NgbAccordion;
  get stocksFormArray() { return this.stocksForm.get('stocksFormArray') as FormArray; }

  constructor(private modalService: NgbModal) { }

  ngOnInit(): void {
    this.stocksForm = new FormGroup({ stocksFormArray: new FormArray([]) });

    this.inputs.forEach(i => {
      let validators = [
        Validators.required, Validators.maxLength(i.mx_l),
        NoWhitespace, OnlyNumbers(i.o_n), GreaterThanZero(i.g_t_z)
      ];
      this.stocksFormArray.push(new FormControl(i.value, validators));
    });
  }

  ngAfterViewInit(): void {
    this.openInputPanel();
  }

  openInputPanel(): void {
    this.ngbAccordion.expand('input-panel');
    this.ngbAccordion.collapse('result-panel');
  }

  openModalStocksInput(stockItem: any): void {
    const modalRef = this.modalService.open(ModalSimpleComponent, { size: 'xl' });
    modalRef.componentInstance.title = 'Explanations for New Stock Edit and Input Elements';
    modalRef.componentInstance.body = stockItem.description;
  }

  getInputs() {
    return [
      { i: 1, mx_l: 30, g_t_z: false, o_n: false, ty: 'text', name: 'STK', value: '', ti: 'Stk Name, Sym, and Exchange:', titleExtra: '(30 chars or less)', description: 'The purpose of this entry is for the user to uniquely identify the stock to be evaluated. For posting, it is advised that the correct stock symbol be included.' },
      { i: 2, mx_l: 10, g_t_z: true, o_n: true, ty: 'tel', name: 'SS', value: '', ti: 'Number of Shares in Millons:', titleExtra: '(Gtr than 0)', description: 'The entry for the Number of Shares is based on the number of outstanding common shares that is used in financial reports to determine earnings per share, dividends per share, etc. The number entered represents the number of shares in millions; thus, 150,750,000 shares is entered as 150.75. A positive number greater than zero must be entered.' },
      { i: 3, mx_l: 10, g_t_z: false, o_n: true, ty: 'tel', name: 'XLTDD', value: '', ti: 'Long-Term Debt in Millons of Dollars: ', titleExtra: '', description: 'A long-term debt of say $100,350,000 is entered as 100.35. When evaluating DJIA scenarios, the Debt to Equity Ratio is entered as .50, .75, 1.76, etc. This ratio is used with the Book Value entry, to estimate the "aggregate" long-term debt of the DJIA Index.' },
      { i: 4, mx_l: 10, g_t_z: false, o_n: true, ty: 'tel', name: 'D', value: '', ti: 'Current Dividend in dollars/sh(xx.xx), annual:', titleExtra: '', description: 'The current annual dividend amount is entered as dollars per share. Thus, a current annual dividend of $2.10 per share is entered as 2.10. For zero dividends,0. is entered or the entry field is left blank.' },
      { i: 5, mx_l: 10, g_t_z: false, o_n: true, ty: 'tel', name: 'BV', value: '', ti: 'Book Value or Net Worth in dollars/sh:', titleExtra: '', description: "The book value or net worth per share entry represents the stock's current value in dollars per share. Thus, a current book value of $15.35 per share is entered as 15.35." },
      { i: 6, mx_l: 10, g_t_z: false, o_n: true, ty: 'tel', name: 'E1', value: '', ti: 'Projected Earnings in dollars/sh, annual:', titleExtra: '', description: 'The projected earnings is entered as dollars per share, and should be viewed as the earnings that are expected in or about a nine to twelve month time period. An expected earnings per share of $5.10 is entered as 5.10. As changes in expected earnings estimates occur over time, the analysis should be reevaluated and updated.' },
      { i: 7, mx_l: 10, g_t_z: false, o_n: true, ty: 'tel', name: 'DEE', value: '', ti: 'Projected Average Growth in Earnings (%):', titleExtra: '', description: 'The projected average rate of growth in earnings should be viewed as the expected trend of earnings expressed as a percentage. This trend should represent an average of projected earning changes over the longer term. Thus, a projected average earnings growth rate of 12.5% is entered as 12.5; a projected decrease of 12.5% is entered as -12.5. As changes in the projected earning growth rate occurs over time, the analysis should be reevaluated and updated. Attention should also be given to the possible effect any of these changes could have on the entry for Projected Earnings ($/share) and Projected Average Growth in Sales (%). It should also be noted that the analysis is very sensitive to earning growth rate values that are less than the value entered for the Current Yield of AAA Bond Yields, and for the zero growth rate case.' },
      { i: 8, mx_l: 10, g_t_z: false, o_n: true, ty: 'tel', name: 'DSS', value: '', ti: 'Projected Average Growth in Sales (%):', titleExtra: '', description: 'The projected average rate of growth in sales should be viewed as the expected trend of sales expressed as a percentage. This trend should represent an average of projected sales changes over the longer term. Thus, a projected 8.5% average sales rate of change is entered as 8.5. As changes in the projected sales growth rate occurs over time, attention should be given to the possible effect any of these changes could have on the entry for Projected Average Growth in Earnings (%). It should be noted that the Value Point calculation can become very sensitive when the entry for the <i>earnings</i> growth rate is less than the value entered for the Current Yield of AAA Bonds and the entry for sales growth rate is only slightly greater or less than the value entered for the Current Yield of AAA Bonds.' },
      { i: 9, mx_l: 10, g_t_z: true, o_n: true, ty: 'tel', name: 'RBB', value: '', ti: 'Current Yield of AAA Bonds:', titleExtra: '(Gtr than 0)', description: "The current AAA bond yield is entered as a percentage. Thus, if the current yield of the S&P AAA corporate bond index is 7.02%, then 7.02 would be entered. This entry represents a low risk reference rate of return factor that the model uses with the projected earnings, projected earnings rate of growth, current dividend and other factors to determine the stock's intrinsic value, its Value Point. The S&P AAA corporate bond index or any equivalent high quality corporate bond index is acceptable. The model is very sensitive to situations where the entry value of the projected earnings rate of growth is less than that of the AAA bond yield, and the entry value for the sales rate of growth is also less than, or slightly greater than the AAA bond yield. The number entered for the AAA bond yield must be greater than zero." },
      { i: 10, mx_l: 10, g_t_z: false, o_n: true, ty: 'tel', name: 'DRBB', value: '', ti: 'Projected Change of AAA Bonds(%):', titleExtra: '', description: 'The projected change of AAA bond yields, or any high grade corporate bond index, is entered as the difference in yield between the current yield and the projected yield. Thus, if the current yield is 7.02% and is forecasted to increase to 7.08%, then 0.06 would be entered. If the current 7.02% yield is forecasted to decrease to 6.94%, then -0.08 would be entered.' },
      { i: 11, mx_l: 10, g_t_z: false, o_n: true, ty: 'tel', name: 'E0', value: '', ti: 'Current Earnings in dollars/sh, annual amount: ', titleExtra: '', description: "The current earnings entry is entered as dollars per share. A current earnings per share of$4.35, is entered as 4.35. This entry is not used in calculating the stock's Value Point. Its only use is in the output report to show the current price to earnings ratio (CURR.P/E)." },
      { i: 12, mx_l: 10, g_t_z: true, o_n: true, ty: 'tel', name: 'CP', value: '', ti: 'Current Price in dollars/sh: ', titleExtra: '(Gtr than 0)', description: "The current price entry is entered as dollars per share. A current price per share of $54.35is entered as 54.35. This entry is not used in calculating the stock's Value Point. Its only use is in the output report to show the current price to earnings ratio (CURR.P/E), and the calculated Value Point to current price ratio (VAL PT./CURR.PR.)" },
      { i: 13, mx_l: 10, g_t_z: false, o_n: true, ty: 'tel', name: 'HH', value: '', ti: 'Number of Years the Earnings Growth Rate is Expected to be Sustained:', titleExtra: '(e.g. 1, 1.5, 2 etc.)', description: 'The number of years that the projected average rate of growth in earnings is expected to be sustained, is entered as any number equal to or greater than one. For conservative and base case evaluations, it is appropriate to assume a one-year projection period. Thus, the number 1.0 or 1 would be entered. For situations where the current price of the stock appears to have discounted future earnings beyond one year, it would be interesting for the evaluator to explore several yearly time periods, e.g. for a one and a half year projection, 1.5 would be entered, for a two year projection, 2 would be entered, etc. It is important to note that the "Number of Years" entry controls the effect that the "Projected Average Growth in Earnings "entry has on the model' + "'" + 's calculation of future earnings (beyond one year). Thus, the greater the value that is entered for the number of years of <i>sustained</i> earnings growth, the greater the uncertainty and risk that is associated with the calculated Value Point. For situations where the projected earnings entry is positive and greater than the current earnings and where the <i>sustainable</i> earnings growth rate is greater than the current yield for high quality corporate bonds, then a good base case entry for the "Number of Years" input would be the <i>sustainable</i> earnings growth rate divided by the bond yield. If the resulting evaluation has a risk factor that is greater than one, then consider reducing the value for the "Number of Years" input to where the resulting evaluation has a risk factor that is close to or less than one.' },
    ];
  }

  submitForm(): void {
    this.gettingResults = true;
    setTimeout(() => {
      var vpaEngine = new VPAEngine.VpaEngine();

      let vpaModel: VpaUser = {
        STK: this.getInputValue('STK') as string,
        SS: this.getInputValue('SS') as number,
        XLTDD: this.getInputValue('XLTDD') as number,
        D: this.getInputValue('D') as number,
        BV: this.getInputValue('BV') as number,
        E1: this.getInputValue('E1') as number,
        DEE: this.getInputValue('DEE') as number,
        DSS: this.getInputValue('DSS') as number,
        RBB: this.getInputValue('RBB') as number,
        DRBB: this.getInputValue('DRBB') as number,
        E0: this.getInputValue('E0') as number,
        CP: this.getInputValue('CP') as number,
        HH: this.getInputValue('HH') as number
      }

      let result = vpaEngine.Calculate(vpaModel, ModelAnalysis.Stocks);
      this.results = [
        { name: 'STK', decimal: 0, prefix: '', sufix: '', value: result.STK, title: 'NAME SYM EXCH' },
        { name: 'V', decimal: 0, prefix: '$', sufix: ' per share', value: result.V, title: 'Value Point' },
        { name: 'E1', decimal: 0, prefix: '$', sufix: '', value: result.E1, title: 'Forward Earnings' },
        { name: 'X1', decimal: 0, prefix: '$', sufix: '', value: result.X1, title: 'Forward Price to Earnings' },
        { name: 'X2', decimal: 2, prefix: '', sufix: '', value: result.X2, title: 'Value Point per Currnent Earning' },
        { name: 'X3', decimal: 0, prefix: '$', sufix: '', value: result.X3, title: 'Current Price to Earnings' },
        { name: 'X4', decimal: 4, prefix: '', sufix: '%', value: result.X4, title: 'Current Yield ' },
        { name: 'CP', decimal: 0, prefix: '$', sufix: '', value: result.CP, title: 'Current Price' },
        { name: 'RRF', decimal: 2, prefix: '', sufix: '', value: result.RRF, title: 'Relative Risk Factor' },
      ];
      this.ngbAccordion.collapse('input-panel');
      this.ngbAccordion.expand('results-panel');
      this.gettingResults = false;
    }, 500)
  }

  getInputValue(name: string) {
    let value: string | number = '';
    this.inputs.forEach((input, i) => {
      if (input.name === name) {
        value = this.stocksFormArray.controls[i].value;
      }
    });
    if (isNaN(Number(value))) {
      return `${value}`;
    } else {
      return Number(value);
    }
  }

  resetInputValues(): void {
    var originalInputs = this.getInputs();
    this.stocksFormArray.controls.forEach((c, i) => {
      c.patchValue(originalInputs[i].value)
    });
  }
}