import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable()
export class HttpsGuard implements CanActivate {
  canActivate(): boolean {
    if (environment.production && (location.protocol !== 'https:')) {
      let url = location.host;
      let domain = url.split(".").slice(-2).join(".");
      location.href = `https://www.${domain}`;
      return false;
    }
    return true;
  }
}