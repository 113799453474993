import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-links',
  templateUrl: './links.component.html',
  styleUrls: ['./links.component.scss']
})
export class LinksComponent implements OnInit {

  items = this.getItems();

  constructor() { }

  ngOnInit() {
  }

  getItems() {
    return [
      { href: 'http://www.about-online-trading.com/', name: 'About On Line Trading', description: '' },
      { href: 'http://www.absolute-futures.com/', name: 'Absolute - Futures', description: '' },
      { href: 'http://www.barra.com/', name: 'Barra, On Line Products Center', description: '' },
      { href: 'http://www.index-day-trading.com', name: 'Daytrading Signals, Timing systems for trading US indexes', description: '' },
      { href: 'http://www.daytraderpro.com', name: 'DayTraderPro ', description: '' },
      { href: 'http://www.doubtfulaccounts.com', name: 'Doubtful Accounts ', description: '' },
      { href: 'http://www.eCharts.com/', name: 'eCharts ', description: 'A resource for individual investors looking to learn more about investing, technical analysis, and charting.' },
      { href: 'http://www.financials.com', name: 'E-Line Financials ', description: '' },
      { href: 'http://www.edgar-online.com', name: 'EDGAR ONLINE (SEC Filings)', description: '' },
      { href: 'http://www.finpipe.com', name: 'Financial Pipeline', description: '' },
      { href: 'http://www.fundtrader.com', name: 'FundTrader.com, Market timing made easy', description: '' },
      { href: 'http://www.futures-trading-commodity-trading.com', name: 'Futures Trading Commodity Trading.com', description: '' },
      { href: 'http://www.goldstock.com', name: 'Goldstock', description: '' },
      { href: 'http://www.imfnet.com/pitbull/links1.html', name: 'INVEST$LINK ', description: '' },
      { href: 'http://www.investorguide.com', name: 'InvestorGuide ', description: '' },
      { href: 'http://www.investorsoftware.com', name: 'investorsoftware.com ', description: '' },
      { href: 'http://www.jjjinvesting.com', name: 'JJJ Investing Services ', description: '' },
      { href: 'http://www.lowrisk.com', name: 'Lowrisk.com ', description: '' },
      { href: 'http://www.MarketVolume.com', name: 'MarketVolume offers real time volume and technical analysis.', description: '' },
      { href: 'http://www.mfinance.com', name: 'M Finance ', description: '' },
      { href: 'http://www.nasdaq.com', name: 'NASDAQ', description: '' },
      { href: 'http://onlinestocks.org', name: 'Online Stocks', description: 'One of the top penny stock sites for company research.' },
      { href: 'http://www.optionsmart.com', name: 'OptionSmart', description: 'Stock option analysis based on technicals and fundamentals.' },
      { href: 'http://www.pennystockinsider.com', name: 'Penny Stock Insider', description: 'New investor section, penny stock research and reports, free information and releases.' },
      { href: 'http://stockinsiders.org', name: 'Penny Stock Research', description: 'Learn about the next big movers and special situation stocks.' },
      { href: 'http://pennystockworld.com', name: 'PennyStockWorld.com', description: 'A source for advice on investing, researching stocks, discount brokers, hot company reports, and more.' },
      { href: 'http://pennystockz.com', name: 'PennyStockZ.com', description: 'Free feature articles on hot sectors and industries and pennystock trading reports.' },
      { href: 'http://qqq-trading-system.com/', name: 'QQQ Trading Systems', description: 'QQQ timing signals with 10-20 conservative trades in a year. 112 % over the past 12 month' },
      { href: 'http://www.quote.com/', name: 'QuoteCom', description: '' },
      { href: 'http://www3.sympatico.ca/homenuck/', name: 'Secrets of Pennystocks', description: 'Learn 5 powerful investment secrets for trading in low-priced companies.' },
      { href: 'http://www.secapl.com', name: 'Security APL Inc., Portfolio Accounting & Performance Measurement Software', description: '' },
      { href: 'http://www.techstocks.com', name: 'Silicon Investor ', description: '' },
      { href: 'http://pennystockinsiders.com', name: 'Small-Cap and Micro-Cap Stock Insider', description: 'New investor section, details about investing in small-cap and micro-cap issues.' },
      { href: 'http://www.speedtrader.com', name: 'Speedtrader ', description: '' },
      { href: 'http://www.spdrs-trading-system.com', name: 'S&P 500 Timing System ', description: '' },
      { href: 'http://www.sptrading.com', name: 'SPtrading.Com ', description: '' },
      { href: 'http://www.stock-charts-analysis.com', name: 'Stock Charts Analysis', description: 'Real time volume charts for stocks, indexes and exchanges. Volume based analysis and signals.' },
      { href: 'http://stockinsiders.com', name: 'StockInsiders.com', description: 'Stock Insiders provides free reports on penny stock companies, hot industries, and discount brokers.' },
      { href: 'http://stockinsiders.net', name: 'StockInsiders Network', description: 'Provides a great deal of information and reports about penny stocks.' },
      { href: 'http://www.stockfever.com/', name: 'Stockfever.com', description: '' },
      { href: 'http://www.stockmaster.com/', name: 'StockMaster', description: '' },
      { href: 'http://www.stockmaven.com/', name: 'Stock Maven', description: 'Stock research center, real time quotes and news, live financial markets data, research tools to evaluate and track stocks of publicly held companies.' },
      { href: 'http://www.stockpickcentral.com/', name: 'STOCKPICKCENTRAL.COM ', description: '' },
      { href: 'http://www.stockz.com/', name: 'STOCKZ.COM', description: 'Provides a stock pick of the week, short term picks, long term picks, option picks, and mutual fund picks free to the public.' },
      { href: 'http://www.streetinsider.com/', name: 'StreetInsider.com ', description: '' },
      { href: 'http://www.demdata.com', name: 'The Chapman Company', description: '' },
      { href: 'http://www.deardow.com', name: 'The Dear Dow Letter', description: '' },
      { href: 'http://www.interferenza.net/wwfn/al/affiliates.cgi?15', name: 'THE PITBULL INVESTOR', description: '' },
      { href: 'http://www.pristine.com', name: 'The Pristine Day Trader', description: '' },
      { href: 'http://www.ragingbull.com', name: 'The Raging Bull', description: '' },
      { href: 'http://www.flash.net/~hesler', name: 'The Short Term Stock Selector', description: '' },
      { href: 'http://www.timevalue.com', name: 'TimeValue Software ', description: '' },
      { href: 'http://www.tradersfloor.com', name: 'TradersFloor - Technical Analysis and Market Timing', description: '' },
      { href: 'http://www.ultrafs.com', name: 'ULTRA Financial Systems ', description: '' },
      { href: 'http://www.virtualdaytrader.net', name: 'VirtualDayTrader.net', description: '' },
      { href: 'http://www.stockpickoftheday.com', name: 'www.stockpickoftheday.com', description: '' },
      { href: 'http://quote.yahoo.com', name: 'Yahoo Quotes', description: '' },
    ];
  }
}